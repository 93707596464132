import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  ngOnInit(): void {
    this.loc= localStorage.getItem('Location');
  }
  public sidebarMinimized = false;
  public navItems = navItems;
  public loc: any;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
