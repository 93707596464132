import { Component, OnInit, NgModule  } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../../services/report.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  
  model : any={};
  loginForm: FormGroup;
  submitted = false;
  UserName: any;
  Password: any;

  constructor( private router: Router, private LoginService:ReportService, private toastr: ToastrService) {
  }

  ngOnInit() {
    // this.loginForm = this.formBuilder.group({
    //   UserName: ['', Validators.required],
    //   Password: ['', Validators.required]
    // });
  }

  OnLoginAjman() {

    // console.log(this.UserName);
    // console.log(this.Password);
    this.model={
      UserName: this.UserName,
      Password: this.Password
    }
    // console.log(this.model);
    this.LoginService.Login(this.model).subscribe(data=>{
     // console.log(data);
      if(data[0]["Column1"]===1) {
        localStorage.setItem('Location', 'AJMAN');
        this.router.navigate(['/dashboard']);
      }
      else {
        // alert ("Login Falid");
        this.toastr.warning("Invalid Username And Password.!","Login Failed!");
      }

      });

  }

  OnLoginSharja() {
    this.model={
      UserName: this.UserName,
      Password: this.Password
    }
    // console.log(this.model);
    this.LoginService.Login(this.model).subscribe(data=>{
     // console.log(data);
      if(data[0]["Column1"]===1) {
        localStorage.setItem('Location', 'SHARJAH');
      this.router.navigate(['/dashboard']);
      }
      else {
        // alert ("Login Falid");
        this.toastr.warning("Invalid Username And Password.!","Login Failed!");
      }

      });
        
  }

 }
