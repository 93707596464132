import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    // apiURL = 'http://localhost:53535/api/sales';
    // apiURL1 = 'http://localhost:53535/api/employee';
 apiURL = 'https://baitalsyria.com/api/sales';
 apiURL1 = 'https://baitalsyria.com/api/employee';
  fb: any;
  constructor(public http: HttpClient) { }
  header : any;
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  GetHouseBill(fdate :any, tdate: any, cusName : any, CusType: any): Observable<any> {
    return this.http.get<any>(this.apiURL1 + '/GetAllHouseBill?fdate='+fdate+'&tdate='+tdate+'&cname='+cusName+'&ctype='+CusType+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetCustomer(): Observable<any> {
    return this.http.get<any>(this.apiURL1 + '/GetAllCustomer')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalesMan(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetSalesman?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalesDetails(fdate :any,tdate :any, sman :any): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetSalesDetail?loc='+localStorage.getItem('Location')+'&fdate='+fdate+'&tdate='+tdate+'&sman='+sman+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalesDate(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetLastSaleDate?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalesGrTotal(fdate : any,tdate :any, sman :any): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetSalesGrTotal?loc='+localStorage.getItem('Location')+'&fdate='+fdate+'&tdate='+tdate+'&sman='+sman+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetCusTypes(): Observable<any> {
    return this.http.get<any>(this.apiURL1 + '/GetBTypes')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetTotSales(): Observable<any> {
    // console.log(localStorage.getItem('Location'));
    return this.http.get<any>(this.apiURL + '/GetTotSales?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalPeriod(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetLastSeven?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalPeriodSum(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetLastSevenSum?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalPeriodCompare(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetLastSalesCompare')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  GetSalPeriodNetSum(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetLastSevenTotSum?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCategorySales(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/GetCategorySales?loc='+localStorage.getItem('Location')+'')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  form: FormGroup;
  Login(model: any): Observable<any> {
    // this.form = this.fb.group({
    //   UserName: uname,
    //   Password: pwd
    // });
    // console.log(this.fb);
    // var a =this.apiURL+'/Login';
    
   return this.http.post<any>(this.apiURL+'/Login',model,{ headers: this.header})
   .pipe(
    retry(1),
    catchError(this.handleError)
  )
  }


  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }



  // public GetTotSalesRequest(){
  //   this.httpClient.get('https://localhost:44326/Api/User/GetTotSales').subscribe((res)=>{
  //       console.log(res);
  //   });
  //}


}
